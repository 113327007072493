<template>
    <modal ref="modalDesvincularAfiliado" titulo="Desvincular Afiliado" no-aceptar adicional="Desvincular" :tamano="deudas ? 'modal-lg' : 'modal-md'" @adicional="desvincular()">
        <template v-if="!deudas">
            <p class="col-12 text-center text-general">
                Este usuario tiene pedidos sin liquidar <br />
                ¿Desea Desvincular este afiliado?
            </p>
        </template>
        <template v-else>
            <div class="row mx-0">
                <div class="col-auto px-3" />
                <div class="col">
                    <div class="row mx-0 mb-4">
                        <div class="col-12 text-general">
                            Antes de desvincular a este usuario, debes liquidar los pedidos con deuda
                        </div>
                    </div>
                    <div class="row mx-0 px-3 align-items-center">
                        <img :src="avatar" width="36" height="36" class="obj-cover border rounded-circle" />
                        <div class="col-auto text-general f-18">
                            {{ nombre }}
                        </div>
                        <i class="icon-wallet f-18 text-general ml-auto" />
                        <div class="col-auto text-general f-500">
                            {{ convertMoney(valorTotal) }}
                        </div>
                    </div>
                    <div class="row mx-0 mt-4">
                        <p class="col-12 text-general f-15 pl-3 mb-2">
                            Entregados por liquidar
                        </p>
                        <div v-for="pedido in pedidosEntregados" :key="pedido.id" class="col-12 border br-4 py-2 bg-fondo">
                            <div class="row mx-0">
                                <img :src="pedido.imagen" class="br-4 obj-cover" width="40" height="40" />
                                <div class="col">
                                    <p class="f-15 text-general f-500">{{ convertMoney(pedido.valor_pedido) }}</p>
                                    <p class="f-14 text-general mt-1">{{ pedido.propietario_nombre }}</p>
                                </div>
                                <div class="col">
                                    <p class="f-15 text-general f-500">No. {{ pedido.id }}</p>
                                    <p class="f-14 text-general mt-1">{{ formatearFecha(pedido.entrega_fecha) }}</p>
                                </div>
                                <div class="col">
                                    <p class="f-15 text-general"> 
                                        <i class="icon-product" /> 
                                        {{ pedido.productos_count }}
                                    </p>
                                    <p class="f-15 text-general mt-1"> 
                                        <i class="icon-shopping-check" /> 
                                        {{ estadoPedido(pedido.estado) }}
                                    </p>
                                </div>
                                <div v-if="pedido.liquidado" class="col pr-0">
                                    <p class="text-general f-15">
                                        <i class="icon-truck-check text-green" />
                                        <b class="ml-2">Liquidado</b>
                                    </p>
                                    <p class="text-general f-15 mt-1 text-right">
                                        {{ formatearFecha(pedido.liquidado) }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 mt-4">
                        <p class="col-12 text-general f-15 pl-3 mb-2">
                            Pedido que quedarán fuera del convenio
                        </p>
                        <div v-for="pedido in pedidosEntregados" :key="pedido.id" class="col-12 border br-4 py-2">
                            <div class="row mx-0">
                                <img :src="pedido.imagen" class="br-4 obj-cover" width="40" height="40" />
                                <div class="col">
                                    <p class="f-15 text-general f-500">{{ convertMoney(pedido.valor_pedido) }}</p>
                                    <p class="f-14 text-general mt-1">{{ pedido.propietario_nombre }}</p>
                                </div>
                                <div class="col">
                                    <p class="f-15 text-general f-500">No. {{ pedido.id }}</p>
                                    <p class="f-14 text-general mt-1">{{ formatearFecha(pedido.entrega_fecha) }}</p>
                                </div>
                                <div class="col">
                                    <p class="f-15 text-general"> 
                                        <i class="icon-product" /> 
                                        {{ pedido.productos_count }}
                                    </p>
                                    <p class="f-15 text-general mt-1"> 
                                        <i class="icon-shopping-check" /> 
                                        {{ estadoPedido(pedido.estado) }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto px-3" />
            </div>
        </template>
    </modal>
</template>

<script>
import detalleAfiliado from '~/services/nomina/detalleAfiliado'

const iconos = {
    1: {icon: 'icon-receipt', nombre: 'Creado'},
    2: {icon: 'icon-ok-circled', nombre: 'Confirmado'},
    201: {icon: 'icon-cart-arrow-down', nombre: 'Alistando'},
    202: {icon: 'icon-shopping-outline', nombre: 'Empacado'},
    3: {icon: 'icon-truck-fast-outline', nombre: 'Enviado'},
    4: {icon: 'icon-shopping', nombre: 'Finalizado'},
    110: {icon: 'icon-ok-circled', nombre: 'Ajustes Tienda en Proceso'},
    11: {icon: 'icon-ok-circled', nombre: 'Ajustes de Tienda'},
    12: {icon: 'icon-ok-circled', nombre: 'Ajustes confirmados'},
    13: {icon: 'icon-ok-circled', nombre: 'Confirmado con Cambios Cliente'},
    21: {icon: 'icon-ok-circled', nombre: 'Timeout Creado'},
    22: {icon: 'icon-ok-circled', nombre: 'Timeout Confirmado'},
    23: {icon: 'icon-ok-circled', nombre: 'Timeout Cambios Tendero'},
    24: {icon: 'icon-ok-circled', nombre: 'Timeout Cambios Cliente'},
    25: {icon: 'icon-shopping', nombre: 'Rechazado'},
    26: {icon: 'icon-shopping', nombre: 'Cancelado Tienda'},
    27: {icon: 'icon-shopping', nombre: 'Cancelado Cliente'},
    31: {icon: 'icon-shopping', nombre: 'Reclamo'},
    32: {icon: 'icon-shopping', nombre: 'Reclamo Finalizado'},
    33: {icon: 'icon-shopping', nombre: 'Soporte'},
    34: {icon: 'icon-shopping', nombre: 'Soporte Finalizado'},
}

export default {
    props: {
        avatar: {
            type: String,
            default: '/img/no-imagen/sin_user.png'
        },
        nombre: {
            type: String,
            default: '-'
        },
    },
    data(){
        return {
            url_afiliado: 'https://img.freepik.com/foto-gratis/retrato-joven-sonriente-gafas_171337-4842.jpg',
            deudas: true,
            pedidosEntregados: [],
            pedidos: [],
        }
    },
    computed: {
        valorTotal(){
            return _.sumBy(this.pedidosEntregados, el => Number(el.valor_pedido))
        }
    },
    methods: {
        async toggle(){
            await this.dataDesvincular()
            this.$refs.modalDesvincularAfiliado.toggle();
        },
        async dataDesvincular(){
            try {
                const { data } = await detalleAfiliado.dataDesvincular(this.$route.params.id_cliente);
                this.pedidosEntregados = data.pedidos_entregados,
                this.pedidos = data.pedidos
                
            } catch (error){
                this.error_catch(error)
            }
        },
        async desvincular(){
            try {
                const params = {
                    usuario: this.$route.params.id_cliente,
                    con_pedidos: !!(this.pedidos.length)
                }
                const { data } = await detalleAfiliado.desvincular(params);
                if(data.exito){
                    this.notificacion('', 'Desvinculado correctamente', 'success')  
                    this.$refs.modalDesvincularAfiliado.toggle();
                    this.$router.replace({name: 'admin.nomina.ver.empresa.afiliados'})
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        estadoPedido(estado){
            return iconos[estado].nombre
        }
    }
}
</script>